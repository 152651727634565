import useSWR from 'swr';

import { TEN_MINS } from '@/lib/constants';
import { UserUsageInterface } from '@/types';
import { AsyncDataInterface } from '@/types/context-types';
import { request } from '@/utils/api/request';
import { getUsageUrl } from '@/utils/helpers/api-urls';

interface UseUserUsageInterface extends AsyncDataInterface {
  usage?: UserUsageInterface;
}

export const useUsage = (): UseUserUsageInterface => {
  const { data, isLoading, error } = useSWR<UserUsageInterface>(getUsageUrl, request, {
    revalidateOnFocus: false,
    errorRetryCount: 2,
    refreshInterval: TEN_MINS,
  });

  return {
    usage: data,
    isLoading,
    error,
  };
};
